import { render, staticRenderFns } from "./UsersPermissionAddDialog.vue?vue&type=template&id=1a1da163&"
import script from "./UsersPermissionAddDialog.vue?vue&type=script&lang=ts&"
export * from "./UsersPermissionAddDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VAutocomplete,VCardText,VListItemContent,VListItemSubtitle,VListItemTitle})
