var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('v-container', [_c('m-header', {
    attrs: {
      "title": _vm.$t('components.partner.UserTable.title'),
      "breadCrumbs": _vm.breadCrumbs
    },
    scopedSlots: _vm._u([_vm.canCreateUser ? {
      key: "actions",
      fn: function fn() {
        return [_c('user-import-dialog', {
          staticClass: "mx-2",
          attrs: {
            "small": true,
            "outlined": false
          }
        })];
      },
      proxy: true
    } : null], null, true)
  }), _c('paginated-table', {
    attrs: {
      "store": _vm.store,
      "headers": _vm.headers,
      "baseQuery": {},
      "controlElements": _vm.controlElements,
      "selectedItems": _vm.selected,
      "showSelect": true
    },
    on: {
      "update:selectedItems": function updateSelectedItems($event) {
        _vm.selected = $event;
      },
      "update:selected-items": function updateSelectedItems($event) {
        _vm.selected = $event;
      }
    },
    scopedSlots: _vm._u([_vm.canUpdateUser ? {
      key: "selectActions",
      fn: function fn() {
        return [_vm.selected.length ? _c('span', [_c('span', {
          staticClass: "caption"
        }, [_vm._v(_vm._s(_vm.selected.length) + " " + _vm._s(_vm.$t("selected")))]), _c('v-icon', {
          staticClass: "mx-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-arrow-right")]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "text": "",
            "color": "warning"
          },
          on: {
            "click": _vm.openPermissionDialog
          }
        }, [_vm._v(_vm._s(_vm.$t("components.partner.UserTable.addPermissions")))]), _c('v-btn', {
          attrs: {
            "x-small": "",
            "text": "",
            "color": "warning"
          },
          on: {
            "click": _vm.openRolesDialog
          }
        }, [_vm._v(_vm._s(_vm.$t("components.partner.UserTable.manageRoles")))])], 1) : _vm._e()];
      },
      proxy: true
    } : null, {
      key: "item.timestamps.created",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm._f("simpleDoubleDigitDate")(item.timestamps.created)) + " ")];
      }
    }, {
      key: "item.roles",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return _vm._l(item.roles, function (role, i) {
          return _c('v-chip', {
            key: i,
            attrs: {
              "label": "",
              "x-small": ""
            }
          }, [_vm._v(" " + _vm._s(role) + " ")]);
        });
      }
    }, {
      key: "item.permission",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.permission.length) + " ")];
      }
    }, {
      key: "item._id",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('router-link', {
          attrs: {
            "to": {
              name: 'UserAdministrationDetail',
              params: {
                userId: item._id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(item._id) + " ")])];
      }
    }], null, true)
  }), _c('users-permission-add-dialog', {
    ref: "permissions",
    attrs: {
      "users": _vm.selected
    }
  }), _c('users-role-manage-dialog', {
    ref: "roles",
    attrs: {
      "users": _vm.selected
    }
  }), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDeleteDialogActive,
      "title": _vm.$t('components.partner.UserTable.confirmDeleteTitle'),
      "subtitle": _vm.$t('components.partner.UserTable.confirmDeleteSubtitle'),
      "leftLoading": _vm.isDeleteDialogLoading,
      "rightLoading": _vm.isDeleteDialogLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDeleteDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDeleteDialogActive = false;
      },
      "rightClick": _vm.deleteDocument
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }