var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-text-field', {
    staticClass: "mb-n6",
    attrs: {
      "label": _vm.$t('common.nouns.search'),
      "outlined": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm.isReportPortalAppContext && _vm.isAdmin ? _c('div', [_vm._v(" " + _vm._s(_vm.$t("common.nouns.presets")) + ": "), _vm._l(_vm.licenses, function (license) {
    return _c('v-btn', {
      key: license + 'license',
      attrs: {
        "elevation": 0,
        "text": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.applyLicense(license);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("common.LicensesEnum.".concat(license))) + " ")]);
  })], 2) : _vm._e(), _c('v-simple-table', {
    attrs: {
      "fixed-header": ""
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "text-left"
  }, [_vm._v(" " + _vm._s(_vm.$t("common.nouns.permission")) + " ")]), _vm._l(_vm.actions, function (action) {
    return _c('th', {
      key: action,
      staticClass: "text-left pr-8"
    }, [_vm._v(" " + _vm._s(_vm.$t("ActionEnum.".concat(action))) + " ")]);
  })], 2)]), _c('tbody', _vm._l(_vm.filteredPermissions, function (resource) {
    return _c('tr', {
      key: resource.resource
    }, [_c('td', [_vm._v(" " + _vm._s(_vm.$t("BackendResourceEnum.".concat(resource.resource))) + " "), _c('br'), _c('small', [_vm._v(" " + _vm._s(resource.resource) + " ")])]), _vm._l(_vm.actions, function (action) {
      return _c('td', {
        key: action
      }, [_c('v-checkbox', {
        attrs: {
          "value": action,
          "disabled": resource.hasAction(action) || !_vm.isAdmin && !_vm.can(action, resource.resource)
        },
        on: {
          "click": function click($event) {
            return resource.print();
          }
        },
        model: {
          value: resource.actions,
          callback: function callback($$v) {
            _vm.$set(resource, "actions", $$v);
          },
          expression: "resource.actions"
        }
      })], 1);
    })], 2);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }