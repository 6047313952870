var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', {
    staticClass: "title pt-4 mb-n7"
  }, [_c('h4', [_vm._v(" Generelle Informationen: ")])]), _c('v-card', {
    staticClass: "my-2",
    attrs: {
      "flat": "",
      "max-width": "700"
    }
  }, [_c('v-card-text', [_vm.showUsername ? _c('v-text-field', {
    attrs: {
      "rules": _vm.companyUserNameRules,
      "type": "text",
      "label": "Nutzername der Firma *",
      "hint": "Nutzername der Firma. Z. B. mmmint. Darf nur Kleinbuchstaben enthalten. Wird verwendet, um den Partner beim Namen zu suchen.",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.partnerDto.companyUsername,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto, "companyUsername", $$v);
      },
      expression: "partnerDto.companyUsername"
    }
  }) : _vm._e(), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Firmenname *",
      "hint": "Name der Firma. Z. B. mint software GmbH.",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.partnerDto.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto, "companyName", $$v);
      },
      expression: "partnerDto.companyName"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "type": "text",
      "items": _vm.partnerTypes,
      "label": "Typ",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.partnerDto.partnerType,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto, "partnerType", $$v);
      },
      expression: "partnerDto.partnerType"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "type": "text",
      "label": "Country Code",
      "hint": "Country Code - z. B. DE/AT",
      "outlined": "",
      "items": _vm.countryCodes
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(_vm.$t("CountryCodeEnum.".concat(item))) + " ")];
      }
    }, {
      key: "selection",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.$t("CountryCodeEnum.".concat(item))) + " ")];
      }
    }]),
    model: {
      value: _vm.partnerDto.countryCode,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto, "countryCode", $$v);
      },
      expression: "partnerDto.countryCode"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.emailRules,
      "type": "text",
      "label": "Email *",
      "hint": "E-Mail, an die Formulare und Schadensmeldungen gesendet werden",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.partnerDto.contact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto.contact, "email", $$v);
      },
      expression: "partnerDto.contact.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.phoneRules,
      "type": "text",
      "label": "Telefon",
      "hint": "Telefon - z. B. +49 1789 12283847",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.partnerDto.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto.contact, "phone", $$v);
      },
      expression: "partnerDto.contact.phone"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Straße *",
      "hint": "Straße mit Hausnummer - z. B. Auf der Halle 25a.",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.partnerDto.address.street,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto.address, "street", $$v);
      },
      expression: "partnerDto.address.street"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "PLZ *",
      "hint": "Postleitzahl - z. B. 49124",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.partnerDto.address.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto.address, "zip", $$v);
      },
      expression: "partnerDto.address.zip"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.rules,
      "type": "text",
      "label": "Stadt *",
      "hint": "Stadt - z. B. Georgsmarienhütte",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.partnerDto.address.city,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto.address, "city", $$v);
      },
      expression: "partnerDto.address.city"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Bundesland",
      "hint": "Bundesland - z. B. Niedersachsen",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.partnerDto.address.state,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto.address, "state", $$v);
      },
      expression: "partnerDto.address.state"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "type": "text",
      "label": "Adresse Land",
      "hint": "Land (Country Code) - z. B. DE",
      "outlined": "",
      "required": "",
      "items": _vm.countryCodes
    },
    model: {
      value: _vm.partnerDto.address.countryCode,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto.address, "countryCode", $$v);
      },
      expression: "partnerDto.address.countryCode"
    }
  }), _c('geo-finder-field', {
    attrs: {
      "value": _vm.partnerDto.address.geo.lat,
      "address": _vm.partnerDto.address,
      "type": "lat",
      "label": "Geokoordinaten - Latitude *",
      "hint": "Z. B. 50.0000000000000"
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.partnerDto.address.geo, "lat", $event);
      },
      "update:address": function updateAddress($event) {
        return _vm.$set(_vm.partnerDto, "address", $event);
      }
    }
  }), _c('geo-finder-field', {
    attrs: {
      "value": _vm.partnerDto.address.geo.lng,
      "address": _vm.partnerDto.address,
      "type": "lng",
      "label": "Geokoordinaten - Longtitude *",
      "hint": "Z. B. 50.0000000000000"
    },
    on: {
      "update:value": function updateValue($event) {
        return _vm.$set(_vm.partnerDto.address.geo, "lng", $event);
      },
      "update:address": function updateAddress($event) {
        return _vm.$set(_vm.partnerDto, "address", $event);
      }
    }
  })], 1), _c('v-card-title', {
    staticClass: "title pt-4 mb-n7"
  }, [_c('h4', [_vm._v("Kontaktdaten zum anzeigen im Footer")])]), _c('v-card-text', {
    staticClass: "mt-8"
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.emailRules,
      "type": "text",
      "label": "Email *",
      "hint": "Email Anzeige im Footer",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.partnerDto.settings.contact.email,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto.settings.contact, "email", $$v);
      },
      expression: "partnerDto.settings.contact.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": _vm.phoneRules,
      "type": "text",
      "label": "Telefon",
      "hint": "Telefon Anzeige im Footer",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.partnerDto.settings.contact.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto.settings.contact, "phone", $$v);
      },
      expression: "partnerDto.settings.contact.phone"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "rules": _vm.rules,
      "label": "Website Anzeige *",
      "hint": "Anzeigename für die Website, die Sichtbar ist.",
      "outlined": "",
      "required": ""
    },
    model: {
      value: _vm.partnerDto.settings.websites[0].text,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto.settings.websites[0], "text", $$v);
      },
      expression: "partnerDto.settings.websites[0].text"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "Websitelink *",
      "hint": "Link auf den referenziert wird",
      "outlined": "",
      "required": "",
      "rules": _vm.rules
    },
    model: {
      value: _vm.partnerDto.settings.websites[0].link,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto.settings.websites[0], "link", $$v);
      },
      expression: "partnerDto.settings.websites[0].link"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "ABG URL Anzeige",
      "hint": "Anzeigename für die AGB URL, die Sichtbar ist.",
      "outlined": ""
    },
    model: {
      value: _vm.partnerDto.settings.agbUrl.text,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto.settings.agbUrl, "text", $$v);
      },
      expression: "partnerDto.settings.agbUrl.text"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "AGB URL Link",
      "hint": "Link auf die URL der AGB des partners wird",
      "outlined": ""
    },
    model: {
      value: _vm.partnerDto.settings.agbUrl.link,
      callback: function callback($$v) {
        _vm.$set(_vm.partnerDto.settings.agbUrl, "link", $$v);
      },
      expression: "partnerDto.settings.agbUrl.link"
    }
  })], 1), _c('v-card-title', {
    staticClass: "title pt-4 mb-n7"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("partner.PartnerDetailSettingsOpeningHoursCard.openingHours")))])]), _c('v-card-text', {
    staticClass: "mt-8"
  }, _vm._l(Object.values(_vm.WeekDays), function (weekday) {
    return _c('div', {
      key: weekday
    }, [_c('v-text-field', {
      attrs: {
        "type": "text",
        "label": _vm.$t("partner.PartnerDetailSettingsOpeningHoursCard.".concat(weekday)),
        "outlined": ""
      },
      model: {
        value: _vm.partnerDto.settings.openingHours[weekday],
        callback: function callback($$v) {
          _vm.$set(_vm.partnerDto.settings.openingHours, weekday, $$v);
        },
        expression: "partnerDto.settings.openingHours[weekday]"
      }
    })], 1);
  }), 0)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }