var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-btn', {
    attrs: {
      "icon": "",
      "disabled": _vm.isLoading
    },
    on: {
      "click": function click($event) {
        _vm.dialog = true;
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-table-large-plus")])], 1), _c('confirm-action-dialog', {
    attrs: {
      "fullscreen": true,
      "isDialogActive": _vm.dialog,
      "title": _vm.$t('components.partner.AddPermission.title'),
      "subtitle": _vm.$t('components.partner.AddPermission.subtitle'),
      "rightLoading": _vm.isLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.dialog = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.dialog = false;
      },
      "rightClick": function rightClick($event) {
        return _vm.updatePermissions();
      }
    }
  }, [_vm.isAdmin ? _c('v-autocomplete', {
    attrs: {
      "readonly": !!_vm.preselectedPartnerId,
      "rules": _vm.rules,
      "items": _vm.partners,
      "outlined": "",
      "item-value": "_id",
      "item-text": "companyUsername",
      "label": "Partner"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [_c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.companyName)
          }
        }), _c('v-list-item-subtitle', [_vm._v(_vm._s(data.item.companyUsername) + " (" + _vm._s(data.item.id) + ")")])], 1)];
      }
    }], null, false, 3433998752),
    model: {
      value: _vm.partnerId,
      callback: function callback($$v) {
        _vm.partnerId = $$v;
      },
      expression: "partnerId"
    }
  }) : _vm._e(), _vm.partnerId ? _c('user-permission-form', {
    attrs: {
      "permissions": _vm.permissionForResources
    }
  }) : _vm._e(), _c('debug', {
    attrs: {
      "debug": _vm.permissionForResources
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }