var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isDialogActive,
      "title": _vm.$t('components.UsersRoleManageDialog.dialogTitle'),
      "subtitle": _vm.$t('components.UsersRoleManageDialog.dialogSubtitle'),
      "leftDisabled": _vm.isLoading,
      "rightLoading": _vm.isLoading,
      "hideClose": _vm.isLoading
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "leftClick": function leftClick($event) {
        _vm.isDialogActive = false;
      },
      "rightClick": _vm.updateRoles
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("components.UsersRoleManageDialog.rolesToAdd", {
    count: _vm.users.length
  })) + " "), _c('v-select', {
    attrs: {
      "items": _vm.authRoles,
      "multiple": "",
      "chips": "",
      "disabled": _vm.isLoading
    },
    model: {
      value: _vm.roles,
      callback: function callback($$v) {
        _vm.roles = $$v;
      },
      expression: "roles"
    }
  }), _vm.isLoading ? _c('v-card-text', [_vm._v(" " + _vm._s(_vm.counter) + "/" + _vm._s(_vm.users.length) + " ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }