var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal', [_c('debug', [_vm._v(" partnerId " + _vm._s(_vm.partnerId) + " ")]), !_vm.isLoading ? _c('v-container', [_c('m-header', {
    attrs: {
      "breadCrumbs": _vm.breadCrumbList,
      "title": _vm.$t('template.templateTable'),
      "actions": _vm.actions,
      "chips": _vm.chips
    }
  }), _c('template-table', {
    ref: "templateTable",
    staticClass: "mt-2",
    attrs: {
      "partnerId": _vm.partnerId,
      "itemsPerPage": 250
    },
    on: {
      "click:row": _vm.onClickRow
    }
  })], 1) : _c('v-container', [_c('v-skeleton-loader')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }