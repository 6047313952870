import { render, staticRenderFns } from "./UsersRoleManageDialog.vue?vue&type=template&id=70abb71c&"
import script from "./UsersRoleManageDialog.vue?vue&type=script&lang=ts&"
export * from "./UsersRoleManageDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCardText,VSelect})
