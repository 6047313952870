var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-base', {
    attrs: {
      "showFooter": false,
      "displayDivider": true,
      "headerLogo": _vm.logoUrl,
      "isFillHeight": true
    }
  }, [_c('v-container', {
    staticClass: "pa-0",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-row', {
    staticClass: "align-self-stretch",
    attrs: {
      "dense": "",
      "align-self": "stretch"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "4",
      "lg": "3",
      "align-self": "stretch"
    }
  }, [_vm._t("list")], 2), _c('v-divider', {
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    staticClass: "hidden-sm-and-down",
    attrs: {
      "md": "8",
      "lg": "9",
      "align-self": "stretch"
    }
  }, [_vm._t("content")], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }