var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('the-layout-portal-split', {
    scopedSlots: _vm._u([{
      key: "list",
      fn: function fn() {
        return [!_vm.inbox ? _c('latest-entries-card-empty') : _c('div', [_c('partner-message-list-paginated', {
          attrs: {
            "store": _vm.inbox.module,
            "title": _vm.inbox.name,
            "selected": _vm.message
          },
          on: {
            "set": _vm.setMessage
          },
          scopedSlots: _vm._u([{
            key: "options",
            fn: function fn() {
              return [_c('v-menu', {
                attrs: {
                  "left": "",
                  "bottom": "",
                  "offset-y": ""
                },
                scopedSlots: _vm._u([{
                  key: "activator",
                  fn: function fn(_ref) {
                    var on = _ref.on;
                    return [_c('v-btn', _vm._g({
                      attrs: {
                        "icon": "",
                        "text": ""
                      }
                    }, on), [_c('tooltip', {
                      attrs: {
                        "text": _vm.$t("MessageFolderEnum.".concat(_vm.folder))
                      }
                    }, [_c('v-icon', [_vm._v(_vm._s(_vm.messageFolderIconMap.get(_vm.folder)))])], 1)], 1)];
                  }
                }])
              }, [_c('v-card', {
                attrs: {
                  "flat": ""
                }
              }, [_c('v-list', [_c('v-list-item-group', {
                attrs: {
                  "value": _vm.folder
                }
              }, _vm._l(_vm.folderOptions, function (f) {
                return _c('v-list-item', {
                  key: f,
                  attrs: {
                    "value": f
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.setNewFolder(f);
                    }
                  }
                }, [_c('v-list-item-title', [_c('v-icon', [_vm._v(_vm._s(_vm.messageFolderIconMap.get(f)))]), _vm._v(" " + _vm._s(_vm.$t("MessageFolderEnum.".concat(f))) + " ")], 1)], 1);
              }), 1)], 1)], 1)], 1), _c('template-dialog', {
                ref: "templateDialog",
                attrs: {
                  "icon": "mdi-plus",
                  "partnerId": _vm.partnerId,
                  "context": {
                    partner: _vm.partner
                  },
                  "to": [],
                  "from": [_vm.partner]
                }
              })];
            },
            proxy: true
          }])
        })], 1)];
      },
      proxy: true
    }, {
      key: "content",
      fn: function fn() {
        return [_c('div', {
          staticStyle: {
            "height": "calc(100vh - 68px)",
            "overflow-y": "auto",
            "overflow-x": "hidden"
          }
        }, [_vm.isDetailLoading ? _c('v-skeleton-loader', {
          staticClass: "mx-auto",
          attrs: {
            "type": "card@1"
          }
        }) : !_vm.message ? _c('v-card', {
          staticClass: "justify-center align-center",
          attrs: {
            "flat": ""
          }
        }, [_c('v-card-text', [_c('v-img', {
          staticClass: "my-8",
          attrs: {
            "contain": "",
            "src": require("@/assets/undraw/undraw_envelope_re_f5j4.svg"),
            "height": "240",
            "justify": "center"
          }
        })], 1), _c('v-card-title', {
          staticClass: "justify-center"
        }, [_vm._v(" " + _vm._s(_vm.$t("components.PartnerMessageListPaginated.empty")) + " ")]), _c('v-card-subtitle', {
          staticClass: "justify-center",
          attrs: {
            "align": "center"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("components.PartnerMessageListPaginated.selectToRead")) + " ")]), _c('v-card-actions', {
          staticClass: "justify-center"
        }, [_c('router-link', {
          attrs: {
            "to": {
              name: 'PartnerMessageInboxList',
              params: {
                partnerId: _vm.partner.id
              }
            }
          }
        }, [_c('v-btn', {
          attrs: {
            "text": ""
          }
        }, [_vm._v("Inboxen")])], 1)], 1), _c('v-card-actions', {
          staticClass: "justify-center"
        }, [_c('template-dialog', {
          ref: "templateDialog",
          attrs: {
            "large": true,
            "outlined": false,
            "buttonText": _vm.$t('components.PartnerMessageListPaginated.new'),
            "partnerId": _vm.partnerId,
            "context": {
              partner: _vm.partner
            },
            "to": [],
            "from": [_vm.partner]
          }
        })], 1)], 1) : _c('v-container', [_c('partner-message-detail', {
          attrs: {
            "value": _vm.message
          }
        })], 1)], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }